<template>
  <b-container fluid>
    <b-row class="h-100">
      <b-col sm="12" md="7" class="h-100 p-0">
        <div
          style="height: 30%"
          class="
            d-flex
            flex-column
            align-items-center
            text-light
            bg-primary
            d-block d-md-none
          "
        >
          <img src="@/assets/logo.svg" />
          <h5 class="mt-4">نظام مصرفنا الألكتروني</h5>
          <small>برمجة و تصميم فريق Codi</small>
        </div>
        <div
          id="login"
          class="d-flex flex-column justify-content-center align-items-center"
          style="height: 70%"
        >
          <div class="w-50 text-primary">
            <h4 class="mb-5">تسجيل دخول</h4>
            <VForm v-slot="{ onValidate }">
              <VTextField
                v-model="form.phone"
                type="text"
                label="رقم الهاتف"
                rules="required"
              ></VTextField>
              <VTextField
                v-model="form.password"
                type="password"
                label="كلمة المرور"
                rules="required"
              ></VTextField>
              <b-button
                variant="info"
                :disabled="loading"
                block
                pill
                @click="onValidate(login)"
              >
                <template v-if="loading">
                  <b-spinner small></b-spinner>
                </template>
                <template v-if="!loading">تسجيل دخول</template>
              </b-button>
              <b-alert variant="danger" class="mt-2" :show="error"
                >كلمة المرور أو اسم المستخدم خطأ</b-alert
              >
            </VForm>
          </div>
        </div>
      </b-col>
      <b-col class="col-md-5 p-0 h-100 d-none d-md-block">
        <div
          class="
            d-flex
            flex-column
            justify-content-center
            align-items-center
            bg-primary
            w-100
            h-100
            text-light
          "
        >
          <img src="@/assets/logo.svg" width="150" />
          <h5 class="mt-4">نظام مصرفنا الألكتروني</h5>
          <p>برمجة و تصميم فريق Codi</p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      error: false,
      form: {
        phone: null,
        password: null,
      },
    };
  },
  methods: {
    login() {
      this.loading = true;
      this.error = false;
      this.$store
        .dispatch("auth/login", this.form)
        .then(() => {
          this.$router.push("/redirect");
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
  },
};
</script>

<style lang="scss">
.container-fluid {
  height: 100vh !important;
}
.form-group {
  height: auto !important;
}
@media (min-width: 768px) {
  #login {
    height: 100% !important;
  }
}
</style>
